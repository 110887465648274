import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CommunitySubtitle from "./CommunitySubtitle";
import { useSelector } from "react-redux";
import firebase from "../../firebase";
import Moment from "moment";

function CommunityPage1() {
  const user = useSelector((state) => state.user.currentUser);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("notices")
      .onSnapshot((snapshot) => {
        const posts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogPosts(posts);
      });
  }, []);

  return (
    <>
      <CommunitySubtitle />

      {user?.email === "fcokj@daum.net" && (
        <div className="bg-white pt-16">
          <div className="max-w-7xl mx-2 mx-auto flex justify-end ">
            <Link to="/community1-create">
              <span className="border inline-block px-4 py-2 text-sm font-medium hover:bg-gray-600 hover:text-white cursor-pointer rounded-md">
                공지사항 글쓰기
              </span>
            </Link>
          </div>
        </div>
      )}
      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-10">
        <h2 className="font-medium text-3xl mb-4">공지사항</h2>
        <h4 className="font-light text-xl">
          외국인과 동행의 새로운 소식 및 공지사항을 전해 드립니다.
        </h4>
        <div className="w-full flex flex-col mt-10 mb-10">
          <div className="w-full border-t-2 border-yellow-600">
            <span className="w-1/12 inline-block text-center py-3">NO</span>
            <span className="w-7/12 inline-block text-center py-3">제목</span>
            <span className="w-2/12 inline-block text-center py-3">등록일</span>
            <span className="w-1/12 inline-block text-center py-3">조회수</span>
            <span className="w-1/12 inline-block text-center py-3">
              첨부파일
            </span>
          </div>
          <div className="w-full border-b border-yellow-100"></div>

          {/* 반복문시작 */}
          {blogPosts.map((post) => (
            <div className="w-full border-b border-yellow-100" key={post.id}>
              <span className="w-1/12 inline-block text-center py-3">1</span>
              <Link to={`/community1-content/${post.id}`}>
                <span className="w-7/12 inline-block pl-2 py-3">
                  {post.title}
                </span>
              </Link>
              <span className="w-2/12 inline-block text-center py-3">
                {Moment(post.createdAt).format("YYYY-MM-DD")}
              </span>
              <span className="w-1/12 inline-block text-center py-3">11</span>

              {post.attachmentUrl && (
                <span className="w-1/12 inline-block text-center py-3">💾</span>
              )}
            </div>
          ))}
          {/* 반복문종료 */}
        </div>
      </div>
      <div className="max-w-7xl mx-auto mt-10 flex flex-col justify-center items-center mb-10">
        <div className="flex text-xl">
          <div className="px-2">&larr;</div>
          <div className="px-2">1</div>
          <div className="px-2">2</div>
          <div className="px-2">3</div>
          <div className="px-2">4</div>
          <div className="px-2">&rarr;</div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto mt-10 flex justify-center items-center mb-20">
        <form>
          <select
            name="select"
            className="py-2 px-2 border border-gray-200 mr-2"
          >
            <option value="제목" name="title">
              제목
            </option>
            <option value="내용" name="content">
              내용
            </option>
          </select>
          <input
            className="border border-gray-200 w-96 py-2 px-2 max-w-3xl"
            type="text"
            name="search"
          />
          <input
            type="submit"
            value="검색"
            className="py-2 px-4 border border-gray-200"
          />
        </form>
      </div>
    </>
  );
}

export default CommunityPage1;
