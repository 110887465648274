import { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import RegisterPage from "./components/RegisterPage/RegisterPage";
import MainPage from "./components/MainPage/MainPage";
import firebase from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, setUser } from "./redux/actions/user_action";
import HeaderPage from "./components/HeaderPage/HeaderPage";
import TailPage from "./components/TailPage/TailPage";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import IntroPage1 from "./components/IntroPage/IntroPage1";
import IntroPage2 from "./components/IntroPage/IntroPage2";
import IntroPage3 from "./components/IntroPage/IntroPage3";
import IntroPage4 from "./components/IntroPage/IntroPage4";
import BusinessPage1 from "./components/BusinessPage/BusinessPage1";
import BusinessPage2 from "./components/BusinessPage/BusinessPage2";
import BusinessPage3 from "./components/BusinessPage/BusinessPage3";
import BusinessPage4 from "./components/BusinessPage/BusinessPage4";
import BusinessPage5 from "./components/BusinessPage/BusinessPage5";
import DonationPage from "./components/DonationPage/DonationPage";
import CommunityPage1 from "./components/CommunityPage/CommunityPage1";
import CommunityPage2 from "./components/CommunityPage/CommunityPage2";
import CommunityCreate1 from "./components/CommunityPage/CommunityCreate1";
import CommunityContent1 from "./components/CommunityPage/CommunityContent1";
import CommunityCreate2 from "./components/CommunityPage/CommunityCreate2";
import CommunityContent2 from "./components/CommunityPage/CommunityContent2";

function App() {
  let history = useHistory();
  let dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUser(user));
      } else {
        dispatch(clearUser());
      }
    });
  }, [dispatch, history]);

  if (isLoading) {
    return <div>...Loading</div>;
  } else {
    return (
      <>
        <HeaderPage />
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/intro1" component={IntroPage1} />
          <Route exact path="/intro2" component={IntroPage2} />
          <Route exact path="/intro3" component={IntroPage3} />
          <Route exact path="/intro4" component={IntroPage4} />
          <Route exact path="/business1" component={BusinessPage1} />
          <Route exact path="/business2" component={BusinessPage2} />
          <Route exact path="/business3" component={BusinessPage3} />
          <Route exact path="/business4" component={BusinessPage4} />
          <Route exact path="/business5" component={BusinessPage5} />
          <Route exact path="/donation" component={DonationPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/community1" component={CommunityPage1} />
          <Route
            exact
            path="/community1-content/:id"
            component={CommunityContent1}
          />
          <Route exact path="/community1-create" component={CommunityCreate1} />
          <Route exact path="/community2" component={CommunityPage2} />
          <Route exact path="/community2-create" component={CommunityCreate2} />
          <Route
            exact
            path="/community2-content/:id"
            component={CommunityContent2}
          />
        </Switch>
        <TailPage />
      </>
    );
  }
}

export default App;
