import React from "react";
import { Link } from "react-router-dom";
import { GiBanknote } from "react-icons/gi";

function DonationPage() {
  return (
    <>
      <div
        className="flex flex-col h-72 justify-center items-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage:
            "url('https://images.unsplash.com/photo-1592191136579-27d0d2d44e86?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2023&q=80')",
        }}
      >
        <h2 className="font-medium text-4xl text-white">후원안내</h2>
        <h4 className="font-light text-sm uppercase text-gray-200 mt-2">
          Donation
        </h4>
      </div>

      <div className="flex items-center justify-center py-6  border border-b-1">
        <Link to="activity1">
          <span className="px-6 border-r border-gray-200">후원안내</span>
        </Link>
      </div>

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-10">
        <h2 className="font-medium text-4xl mb-4">후원안내</h2>
        <h4 className="font-light text-lg text-center mb-20">
          본 법인은 기획재정부에 등록된 지정기부금 단체로 기부하신 내용에
          대해서는 기부금영수증을 발급해드리고 있습니다. <br />
          많은 후원과 관심을 부탁드립니다
        </h4>
        <div className="flex w-full flex mb-20">
          <div className="flex flex-col w-1/2 justify-center items-center">
            <div className="bg-purple-500 p-6 rounded-full mb-4 ">
              <GiBanknote />
            </div>
            <div>
              <h2 className="font-medium text-2xl text-center mb-2">
                CMS 납부
              </h2>
              <h4 className="font-light text-gray-700 text-center text-lg">
                본 법인에 CMS 신청서를 작성하여 제출하여 주시면 익월부터
                <br />
                자동으로 출금이 됩니다. (CMS 신청서)
              </h4>
            </div>
          </div>
          <div className="flex flex-col w-1/2 justify-center items-center">
            <div className="bg-purple-500 p-6 rounded-full mb-4 "></div>
            <div>
              <h2 className="font-medium text-2xl text-center mb-2">
                개인후원
              </h2>
              <h4 className="font-light text-gray-700 text-center text-lg">
                아래 후원계좌로 납부 후 연락을 주시면 <br />
                개인정보 및 입금내역을 확인 후 기부금 영수증을 발급하여
                드립니다. <br />
                후원계좌 : 농협{" "}
                <span className="text-black font-medium">
                  351-0608-4977-43
                </span>{" "}
                (사단법인 외국인과 동행)
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DonationPage;
