import React from "react";
import BusinessSubtitle from "./BusinessSubtitle";

function BusinessPage3() {
  return (
    <>
      <BusinessSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-4">외국인봉사단</h2>
        <h3 className="mb-20">
          사단법인 외국인과 동행은 지역 사회에서 내·외국인이 공생하고 더불어
          함께 살아가는 살기 좋은 지역을 형성하고자 외국인봉사단을 운영하고
          있습니다. 외국인들에게 지역사회를 함께 만들어가야 한다는 의식도
          인식시키면서 내국인에게는 봉사활동을 통해 외국인의 이미지 향상 등
          긍정적인 효과를 거두고 있습니다. 사단법인 외국인과 동행 봉사단은 중국,
          베트남, 캄보디아, 방글라데시, 스리랑카, 파키스탄, 인도네시아, 네팔,
          태국 등 9개국에서 온 외국인근로자, 다문화가정 60명으로 구성되어 있으며
          매월 봉사활동을 실시하고 있습니다.
        </h3>
        <div className="grid grid-cols-3 w-full gap-8">
          <div className="w-full py-12 bg-blue-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white text-xl">지역미화활동</div>
          </div>
          <div className="w-full py-12 bg-yellow-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white text-xl">
              독거노인돕기 모금
            </div>
          </div>
          <div className="w-full py-12 bg-green-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white text-xl">교통정리봉사</div>
          </div>
          <div className="w-full py-12 bg-purple-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white text-xl">
              법인 행사 자원봉사
            </div>
          </div>
          <div className="w-full py-12 bg-red-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white text-xl">
              국가별 공동체 행사 지원
            </div>
          </div>
          <div className="w-full py-12 bg-indigo-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white text-xl">범죄 예방</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessPage3;
