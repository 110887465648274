import React, { useEffect, useState } from "react";
import CommunitySubtitle from "./CommunitySubtitle";
import firebase from "../../firebase";
import { useParams } from "react-router-dom";
import Moment from "moment";

function CommunityContent2() {
  let { id } = useParams();
  const [notice, setNotice] = useState([]);
  const getNotices = async () => {
    const notices = await firebase
      .firestore()
      .collection("activities")
      .doc(id)
      .get();
    setNotice(notices.data());
  };

  useEffect(() => {
    getNotices();
  });

  return (
    <>
      <CommunitySubtitle />
      <div className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto px-2">
          <div className="">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-16 bg-white">
                <div className="max-w-4xl mx-auto flex flex-col">
                  <div className="flex justify-between mb-12">
                    <div className="flex">
                      <h4 className="sr-only italic text-sm text-gray-500 font-medium text-center my-2 mr-4">
                        조회수:
                      </h4>
                      <h4 className="text-sm italic text-gray-500 font-medium text-center my-2">
                        {Moment(notice.createdAt).format("YYYY-MM-DD")}
                      </h4>
                    </div>
                  </div>
                  <h2 className="text-3xl text-gray-700 font-bold text-center mb-16">
                    {notice.title}
                  </h2>
                  <img
                    className="rounded-lg border"
                    src={notice.attachmentUrl}
                    alt="blog"
                  />
                  <p className="py-4 text-gray-500">{notice.content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityContent2;
