import React, { useEffect, useState } from "react";
import CommunitySubtitle from "./CommunitySubtitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import firebase from "../../firebase";
import Moment from "moment";

function CommunityPage2() {
  const user = useSelector((state) => state.user.currentUser);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("activities")
      .onSnapshot((snapshot) => {
        const posts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogPosts(posts);
      });
  }, []);

  return (
    <>
      <CommunitySubtitle />

      {user?.email === "fcokj@daum.net" && (
        <div className="bg-white pt-16">
          <div className="max-w-7xl mx-2 mx-auto flex justify-end ">
            <Link to="/community2-create">
              <span className="border inline-block px-4 py-2 text-sm font-medium hover:bg-gray-600 hover:text-white cursor-pointer rounded-md">
                활동사진 올리기
              </span>
            </Link>
          </div>
        </div>
      )}

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-3xl mb-4">활동사진</h2>
        <h4 className="font-light text-xl">
          외국인의 한국문화에 대한 활동사진을 전해드립니다.
        </h4>
        <div className="grid grid-cols-4 grid-rows-5 gap-8 w-full mt-10">
          {blogPosts ? (
            blogPosts.map((post) => (
              <Link to={`/community2-content/${post.id}`} key={post.id}>
                <div className="flex flex-col border border-gray-200 p-2">
                  <div
                    className="w-full h-48 mb-3"
                    style={{
                      backgroundImage: `url("${post.attachmentUrl}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <h2 className="font-medium text-lg px-2 mb-1">
                    {post.title}
                  </h2>
                  <h4 className="font-light text-sm px-2">
                    {Moment(post.createdAt).format("YYYY-MM-DD")}
                  </h4>
                </div>
              </Link>
            ))
          ) : (
            <span>데이터가 없습니다.</span>
          )}
        </div>
      </div>
    </>
  );
}

export default CommunityPage2;
