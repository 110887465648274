import React from "react";
import BusinessSubtitle from "./BusinessSubtitle";

function BusinessPage4() {
  return (
    <>
      <BusinessSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-4">문화체험</h2>
        <h4 className="font-light max-w-4xl mb-20">
          사단법인 외국인과 동행은 외국인에게 한국문화에 대한 이해를 높이고자
          다양한 문화체험을 실시하고 있습니다. 주요 문화체험 프로그램으로는
          한국전통의상 체험, 한국전통혼례, 한국전통놀이 체험, 한국전통음식
          만들기 체험, 김장체험, 불국사 템플스테이 등이 있습니다.
        </h4>
        <div className="grid grid-cols-2 border w-full max-w-4xl text-center">
          <div className="col-span-2 p-2">문화체험 프로그램</div>
          <div className="p-2">한국 전통의상 체험</div>
          <div className="p-2">한국 전통혼례 관람</div>
          <div className="p-2">한국 전통음식 만들기 체험</div>
          <div className="p-2">한국 전통춤 체험</div>
          <div className="p-2">김장 체험</div>
          <div className="p-2">경주 엑스포 관람</div>
          <div className="p-2">불국사 템플스테이</div>
          <div className="p-2">한국 산(남산, 보경사 등) 등반</div>
          <div className="p-2">한국수력원자력발전소 견학</div>
          <div className="p-2">양동마을 관람</div>
          <div className="p-2">울산 고래박물관 견학</div>
          <div className="p-2">경주박물관 견학</div>
          <div className="p-2">신라문화제 관람</div>
          <div className="p-2">부산 해양박물관 관람</div>
        </div>
      </div>
    </>
  );
}

export default BusinessPage4;
