import React, { Fragment, useRef, useState } from "react";
import { BookOpenIcon, UsersIcon, CameraIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

// react-awesome-slider
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

// react-modal
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const features = [
  {
    name: "상담사업",
    description:
      "사단법인 외국인과 동행은 한국에 거주하는 외국인들이 겪을 수 있는 다양한 고충애로사항에 대한 상담을 적극지원하고 있습니다",
    icon: BookOpenIcon,
  },
  {
    name: "교육사업",
    description:
      "사단법인 외국인과 동행은 한국에 거주하는 외국인들의 언어능력 향상과 능력 향상을 위해 다양한 교육 프로그램을 실시하고 있습니다.",
    icon: CameraIcon,
  },
  {
    name: "외국인봉사단",
    description:
      "사단법인 외국인과 동행은 지역 사회에서 내·외국인이 공생하고 더불어 함께 살아가는 살기 좋은 지역을 형성하고자 외국인봉사단을 운영하고 있습니다.",
    icon: UsersIcon,
  },
  {
    name: "문화체험",
    description:
      "사단법인 외국인과 동행은 외국인에게 한국문화에 대한 이해를 높이고자 다양한 문화체험을 실시하고 있습니다.",
    icon: UsersIcon,
  },
];

function MainPage() {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-start justify-start min-h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex flex-col">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        법인 공익 위반 시 신고 기관 안내
                      </Dialog.Title>
                      <div className="mt-2 ">
                        <p className="text-sm text-gray-500 mb-1">
                          국민권익위원회 www.acrc.go.kr
                        </p>
                        <p className="text-sm text-gray-500 mb-1">
                          고용노동부 www.moel.go.kr
                        </p>
                        <p className="text-sm text-gray-500">
                          국세청 www.nts.go.kr
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <a
                    href="https://www.acrc.go.kr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      신고사이트이동
                    </button>
                  </a>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <AutoplaySlider
        style={{ height: "100vh" }}
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={6000}
      >
        <div data-src="images/main/carousel1.jpeg">
          <div class="absolute top-1/2 md:top-1/3 left-1/2 md:left-1/3 transform -translate-x-1/2 -translate-y-1/2">
            <h3 className="tracking-tighter text-2xl md:text-6xl font-extrabold text-white text-shadow-md animatie-wiggle animate-fade-in-down mb-3">
              사단법인 외국인과 동행
            </h3>
            <p className="italic tracking-tighter text-xl md::text-3xl text-gray-200 animate-roll animate-fade-in-right mb-4">
              외국인근로자의 인권보호와 복지증진
            </p>
            <Link to="/studyroom">
              <div className="sr-only border rounded-md py-2 px-4 inline-block text-gray-400 hover:border-0 hover:bg-gray-500 hover:text-white animate-fade-in">
                스터디룸 알아보기
              </div>
            </Link>
          </div>
        </div>
        <div data-src="images/main/carousel2.jpeg">
          <div class="absolute top-1/2 md:top-1/3 left-1/2 md:left-1/3 transform -translate-x-1/2 -translate-y-1/2">
            <h3 className="tracking-tighter text-2xl md:text-6xl font-extrabold text-white text-shadow-md animatie-wiggle animate-fade-in-down mb-3">
              후원안내
            </h3>
            <p className="italic tracking-tighter text-xl  md:text-3xl text-gray-500 animate-roll animate-fade-in-right mb-4">
              여러분의 작은힘이 많은이들에게 큰 힘이 됩니다.
            </p>
            <Link to="/donation">
              <div className="border border-gray-600 rounded-md py-2 px-4 inline-block text-gray-400 hover:border-0 hover:bg-gray-500 hover:text-white animate-fade-in">
                후원 알아보기
              </div>
            </Link>
          </div>
        </div>
      </AutoplaySlider>

      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">
              Services
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              사단법인 외국인과 동행
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              저희 법인은 한국에서 열심히 근무하는 외국인근로자의 인권보호와
              복지증진을 도모하여 외국인근로자가 한국에서 안정된 생활의 틀을
              마련하고 삶을 질을 향상시키고자 상담, 교육 등 다양한 프로그램을
              운영하고 있습니다.
            </p>
          </div>

          <div className="mt-16">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-gray-200">
        <div className="max-w-7xl mx-auto py-16 px-2 sm:px-6 lg:py-24  lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">사단법인 외국인과 동행</span>
            <span className="block text-green-600">
              여러분의 작은힘이 많은이들에게 큰 힘이 됩니다.
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/donation"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                후원안내
              </Link>
            </div>
            <div className="sr-only ml-3 inline-flex rounded-md shadow">
              <a
                href="https://blog.naver.com/PostList.nhn?blogId=himzei&from=postList&categoryNo=70"
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-500"
              >
                네이버블로그
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="relative bg-white overflow-hidden"
        style={{
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage:
            "url('https://images.unsplash.com/photo-1527176930608-09cb256ab504?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1053&q=80')",
        }}
      >
        <div className="pt-16 pb-80 sm:pt-24 sm:py-40 lg:py-48">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg">
              <h1 className="leading-10 italic text-4xl font font-extrabold tracking-tight text-gray-300 sm:text-5xl">
                액티비티
              </h1>
              <p className="mt-4 text-xl text-gray-400">
                #교육 #문화체험 #상담 #외국인봉사단
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                >
                  <div className="absolute transform sm:top-0 sm:translate-x-8 lg:left-1/3 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-64 h-44 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                          <img
                            src="images/photograph/center1.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-64 h-44 rounded-lg overflow-hidden">
                          <img
                            src="images/photograph/center2.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-64 h-44 rounded-lg overflow-hidden">
                          <img
                            src="images/photograph/center3.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-64 h-44 rounded-lg overflow-hidden">
                          <img
                            src="images/photograph/center4.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-64 h-44 rounded-lg overflow-hidden">
                          <img
                            src="images/photograph/center5.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-64 h-44 rounded-lg overflow-hidden">
                          <img
                            src="images/photograph/center6.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-64 h-44 rounded-lg overflow-hidden">
                          <img
                            src="images/photograph/center7.jpg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Link
                  to="/community2"
                  className="inline-block text-center bg-green-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-green-700"
                >
                  자세히보기
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainPage;
