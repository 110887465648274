import React, { useEffect, useRef } from "react";
import Logo from "images/logo.png";
import IntroSubtitle from "./IntroSubtitle";

const options = {
  //지도를 생성할 때 필요한 기본 옵션
  center: new window.kakao.maps.LatLng(35.71196302303815, 129.32593813129662), //지도의 중심좌표.
  level: 3, //지도의 레벨(확대, 축소 정도)
};

function IntroPage4() {
  const container = useRef(null);
  useEffect(() => {
    let map = new window.kakao.maps.Map(container.current, options); //지도 생성 및 객체 리턴

    let markerPosition = new window.kakao.maps.LatLng(
      35.71196302303815,
      129.32593813129662
    );
    let marker = new window.kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
    return () => {};
  }, []);
  return (
    <>
      <IntroSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-40 relative">
        <h2 className="font-medium text-4xl mb-20">찾아오시는 길</h2>
        <div className="w-full">
          <div
            className="map"
            ref={container}
            style={{ width: "100%", height: "500px" }}
          ></div>
        </div>
        <div className="max-w-6xl w-full py-10 px-10 mx-auto flex bg-white justify-center items-center absolute -bottom-24 z-10 border border-b-2 border-r-2">
          <div className="w-1/4 flex justify-center items-center mr-6">
            <img src={Logo} alt="logo" width="240" />
          </div>
          <div className="w-3/4 flex flex-col  justify-center ml-8">
            <h2 className="text-xl text-gray-400 mb-1">
              [사무실] 경상북도 경주시 외동읍 활성길 91
            </h2>
            <h2 className="text-xl text-gray-400 mb-1">
              [교육장] 경상북도 경주시 외동읍 입실로1길 13-29 2층
            </h2>
            <h4 className="font-light">
              [전화] <span className="text-xl font-medium">054)705-1828</span>{" "}
              [팩스] <span className="text-xl font-medium">054)705-2838</span>{" "}
              [이메일]{" "}
              <span className="text-xl font-medium">fcokj@daum.net</span>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntroPage4;
