import React from "react";
import IntroSubtitle from "./IntroSubtitle";

function IntroPage1() {
  return (
    <>
      <IntroSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-20">인사말</h2>
        <div className="grid grid-cols-2 gap-20">
          <div
            className=""
            style={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage:
                "url('https://images.unsplash.com/photo-1629206896310-68433de7ded1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=976&q=80')",
            }}
          ></div>
          <div className="flex flex-col">
            <h2 className="font-medium text-2xl mb-6 text-blue-500">
              경주 사단법인 외국인과 동행
            </h2>
            <h4 className="font-light text-lg mb-6">
              저희 법인은 한국에서 열심히 근무하는 외국인근로자의 인권보호와{" "}
              <br />
              복지증진을 도모하여 외국인근로자가 한국에서 안정된 생활의 틀을
              <br />
              마련하고 삶을 질을 향상시키고자 상담, 교육 등 다양한 프로그램을
              <br />
              운영하고 있습니다. <br />
              <br />
              저희 센터를 통해 많은 외국인근로자가 한국 생활에 잘 적응하고
              <br />
              건강히 가족의 품으로 귀국할 수 있게 지원하도록 하겠습니다.
            </h4>
            <h6>사단법인 외국인과 동행 이사장</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntroPage1;
