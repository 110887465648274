import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhgssAg8NCFCeGvgyVTGZFQkiy8O3zAwc",
  authDomain: "fcokj-f02d2.firebaseapp.com",
  databaseURL:
    "https://fcokj-f02d2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "fcokj-f02d2",
  storageBucket: "fcokj-f02d2.appspot.com",
  messagingSenderId: "1043814364112",
  appId: "1:1043814364112:web:44d6e5a3be9048502a7fd5",
  measurementId: "G-T9BLHFSYSE",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
