import React from "react";
import { Link } from "react-router-dom";

function CommunitySubtitle() {
  return (
    <>
      <div
        className="flex flex-col h-72 justify-center items-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage:
            "url('https://images.unsplash.com/photo-1598476543599-72c8a60894d7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')",
        }}
      >
        <h2 className="font-medium text-4xl text-white">커뮤니티</h2>
        <h4 className="font-light text-sm uppercase text-gray-200 mt-2">
          Community
        </h4>
      </div>

      <div className="flex items-center justify-center py-6  border border-b-1">
        <Link to="/community1">
          <span className="px-6 border-r border-gray-200">공지사항</span>
        </Link>
        <Link to="/community2">
          <span className="px-6 border-r border-gray-200">활동사진</span>
        </Link>
      </div>
    </>
  );
}

export default CommunitySubtitle;
