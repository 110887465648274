import React from "react";
import BusinessSubtitle from "./BusinessSubtitle";

function BusinessPage1() {
  return (
    <>
      <BusinessSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-4">상담사업</h2>
        <h3 className="mb-20">
          사단법인 외국인과 동행은 한국에 거주하는 외국인들이 겪을 수 있는
          다양한 고충애로사항에 대한 상담을 적극지원하고 있습니다
        </h3>
        <div className="grid grid-cols-3 w-full gap-8">
          <div className="w-full h-56 bg-blue-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white mb-4 text-xl">노무</div>
            <div className="w-5/6 h-1/2 bg-white py-2 px-4">
              체불임금, 퇴직급여 계산, 산업재해 보상, 산업재해에 따른 기타 행정
              업무 등
            </div>
          </div>
          <div className="w-full h-56 bg-green-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white mb-4 text-xl">법률</div>
            <div className="w-5/6 h-1/2 bg-white py-2 px-4">
              사기, 폭행 등 민사적 문제, 법률 상담 등{" "}
            </div>
          </div>
          <div className="w-full h-56 bg-yellow-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white mb-4 text-xl">비자</div>
            <div className="w-5/6 h-1/2 bg-white py-2 px-4">
              고용허가제 및 출입국 행정신고, E-7-4, F-2-6 비자 변경 지원 등
            </div>
          </div>
          <div className="w-full h-56 bg-red-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white mb-4 text-xl">교육</div>
            <div className="w-5/6 h-1/2 bg-white py-2 px-4">
              한국어 교육, 한국어능력시험, 사회통합프로그램 등
            </div>
          </div>

          <div className="w-full h-56 bg-gray-600 flex flex-col justify-center items-center">
            <div className="font-medium text-white mb-4 text-xl">
              일상생활 및 기타
            </div>
            <div className="w-5/6 h-1/2 bg-white py-2 px-4">
              부동산 계약, 휴대폰 개통, 무료 진료 등
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessPage1;
