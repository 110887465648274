import React from "react";
import IntroSubtitle from "./IntroSubtitle";

function IntroPage2() {
  return (
    <>
      <IntroSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-20">연 혁</h2>
        <ul className="">
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2011.11.</h2>
            <h4 className="font-light ">경주외국인센터 개소(경주시 노동동) </h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium  mr-6 text-right">2013.01.</h2>
            <h4 className="font-light">
              경주외국인센터 이전(경주시 외동읍 입실리){" "}
            </h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium  mr-6 text-right">2013.04.</h2>
            <h4 className="font-light ">경주경찰서“외국인 도움센터”지정</h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2013.10.</h2>
            <h4 className="font-light ">
              고용노동부 사단법인 인가(사단법인 경주외국인센터)
            </h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2013.12.</h2>
            <h4 className="font-light ">
              한국산업인력공단 “소지역 상담센터”선정
            </h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2013.12.</h2>
            <h4 className="font-light ">기획재정부 “지정기부금 단체”선정</h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2014.12.</h2>
            <h4 className="font-light ">경상북도 비영리민간단체 등록</h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2015.01.</h2>
            <h4 className="font-light ">
              법무부 울산출입국사무소 이민자 조기적응프로그램 운영기관 선정
            </h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2015.12.</h2>
            <h4 className="font-light ">
              법무부 울산출입국사무소 사회통합프로그램 일반운영기관 선정
            </h4>
          </li>
          <li className="mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2016.05.</h2>
            <h4 className="font-light ">행정자치부장관 표창 수여</h4>
          </li>
          <li className=" mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2017.07.</h2>
            <h4 className="font-light ">
              법무부 울산출입국사무소 중국동포 조기적응프로그램 운영기관 선정
            </h4>
          </li>
          <li className=" mb-2 flex">
            <h2 className="font-medium mr-6 text-right">2020.11.</h2>
            <h4 className="font-light ">
              법인 상호 변경(사단법인 외국인과 동행)
            </h4>
          </li>
        </ul>
      </div>
    </>
  );
}

export default IntroPage2;
