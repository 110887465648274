import React from "react";
import BusinessSubtitle from "./BusinessSubtitle";

function BusinessPage2() {
  return (
    <>
      <BusinessSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-4">교육사업</h2>
        <h3 className="mb-20">
          사단법인 외국인과 동행은 한국에 거주하는 외국인들의 언어능력 향상과
          능력 향상을 위해 다양한 교육 프로그램을 실시하고 있습니다.
        </h3>
        <div className="bg-blue-50 w-full p-10">
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              한국어교육(한국어 기초·초급·중급 / TOPIK Ⅰ·Ⅱ)
            </div>
            <div className="font-medium text-xl col-span-2">
              매주 토요일, 일요일
            </div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              조기적응교육(중국동포 / E-9 외국인근로자)
            </div>
            <div className="font-medium text-xl col-span-2">월 1~2회</div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              사회통합프로그램(2단계, 3단계, 4단계)
            </div>
            <div className="font-medium text-xl col-span-2">
              2월, 5월, 9월 개강
            </div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              귀국의식함양교육
            </div>
            <div className="font-medium text-xl col-span-2">월 1~2회</div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              안전 교육(소방 교육, 심폐소생술 등)
            </div>
            <div className="font-medium text-xl col-span-2">연 1회</div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              기초생활교육
            </div>
            <div className="font-medium text-xl col-span-2">월 1~2회</div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              외국인 운전면허교실
            </div>
            <div className="font-medium text-xl col-span-2">월 1회</div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              외국인 직업능력향상 교육
            </div>
            <div className="font-medium text-xl col-span-2">월 2회</div>
          </div>
          <div className="w-full bg-white p-8 border-b grid grid-cols-5">
            <div className="font-medium text-xl text-blue-600 col-span-3">
              컴퓨터 활용교육
            </div>
            <div className="font-medium text-xl col-span-2">연 2회</div>
          </div>
        </div>
        <h4 className="text-red-500 flex flex-start">
          ※ 상기 교육 프로그램은 사업 추진 및 타 기관 협조에 따라 프로그램
          일부가 조정 또는 변경이 될 수 있습니다.
        </h4>
      </div>
    </>
  );
}

export default BusinessPage2;
