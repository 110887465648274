import React from "react";
import BusinessSubtitle from "./BusinessSubtitle";

function BusinessPage5() {
  return (
    <>
      <BusinessSubtitle />
      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-4">기타 사업</h2>
        <h4 className="font-light max-w-4xl mb-20">
          사단법인 외국인과 동행은 외국인에게 필요한 다양한 사업을 추진하여
          외국인들이 지역사회 조기 적응할 수 있도록 돕고 있습니다. 또한 내국인과
          외국인이 함께 서로를 이해하는 축제를 구성하여 운영하고 있으며 국가별
          공동체 행사 지원 등을 통해 지역사회에 다문화가 잘 정착해 갈 수 있도록
          지원하고 있습니다.
        </h4>
        <div className="grid grid-cols-2 border w-full max-w-4xl text-center">
          <div className="col-span-2 p-2">기타 사업 내용</div>
          <div className="p-2">지역리더와 소통마당</div>
          <div className="p-2">세계인의 문화축제</div>
          <div className="p-2">한국어능력시험 모의고사</div>
          <div className="p-2">캄보디아 쫄츠남 축제</div>
          <div className="p-2">주말 원동기 면허시험</div>
          <div className="p-2">스리랑카 체육대회</div>
          <div className="p-2">무료 결핵 검진</div>
          <div className="p-2">스리랑카·방글라데시 크리킷 대회</div>
          <div className="p-2">외국인 스포츠팀 운영</div>
          <div className="p-2">국제 축구대회</div>
        </div>
      </div>
    </>
  );
}

export default BusinessPage5;
