import React from "react";
import { Link } from "react-router-dom";

function BusinessSubtitle() {
  return (
    <>
      <div
        className="bg-white w-full h-72 flex flex-col justify-center items-center"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage:
            "url('https://images.unsplash.com/photo-1497211419994-14ae40a3c7a3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')",
        }}
      >
        <h2 className="font-medium text-4xl text-white">법인사업</h2>
        <h4 className="font-light text-sm uppercase text-gray-200 mt-2">
          ABOUT THE BUSINESS
        </h4>
      </div>
      <div className="flex items-center justify-center py-6  border border-b-1">
        <Link to="/business1">
          <span className="px-6 border-r border-gray-200">상담사업</span>
        </Link>
        <Link to="/business2">
          <span className="px-6 border-r border-gray-200">교육사업</span>
        </Link>
        <Link to="/business3">
          <span className="px-6 border-r border-gray-200">외국인봉사단</span>
        </Link>
        <Link to="/business4">
          <span className="px-6">문화체험</span>
        </Link>
        <Link to="/business5">
          <span className="px-6">기타사업</span>
        </Link>
      </div>
    </>
  );
}

export default BusinessSubtitle;
