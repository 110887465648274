import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaGoogle } from "react-icons/fa";

function TailPage() {
  return (
    <>
      <div className="py-16 bg-gray-700 divide-y divide-gray-500">
        <div className="max-w-7xl mx-auto px-2 pb-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
          <div className="text-gray-400">
            <h3 className="text-sm mb-3 font-bold text-gray-300">법인소개</h3>
            <h4 className="text-sm mb-3">인사말</h4>
            <h4 className="text-sm mb-3">연혁</h4>
            <h4 className="text-sm mb-3">시설현황</h4>
            <h4 className="text-sm mb-3">찾아오시는 길</h4>
          </div>
          <div className="text-gray-400">
            <h3 className="text-sm mb-3 font-bold text-gray-300">법인사업</h3>
            <h4 className="text-sm mb-3">상담사업</h4>
            <h4 className="text-sm mb-3">교육사업</h4>
            <h4 className="text-sm mb-3">외국인봉사단</h4>
            <h4 className="text-sm mb-3">문화체험</h4>
            <h4 className="text-sm mb-3">기타사업</h4>
          </div>

          <div className="text-gray-400">
            <h3 className="text-sm mb-3 font-bold text-gray-300">활동사진</h3>
            <h4 className="text-sm mb-3">사진</h4>
            <h4 className="text-sm mb-3">언론에 비친 법인</h4>
            <h4 className="text-sm mb-3">&nbsp;</h4>
          </div>
          <div className="text-gray-400">
            <h3 className="text-sm mb-3 font-bold text-gray-300">후원안내</h3>
            <h4 className="text-sm mb-3">후원안내</h4>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-2 pt-4 sm:px-6 lg:px-8 flex flex-col items-center sm:flex-row sm:justify-between ">
          <div className="flex flex-col">
            <div className="text-gray-400 mb-1 flex text-sm items-center">
              <h3 className="text-sm font-bold text-gray-300 mr-1">교육장</h3>
              <h4 className="text-sm mr-2">
                경북 경주시 외동읍 입실로1길 13-29 2층
              </h4>
              <h3 className="text-sm font-bold text-gray-300 mr-1">전화</h3>
              <h4 className="text-sm mr-2">054)705-1828</h4>
              <h3 className="text-sm font-bold text-gray-300 mr-1">팩스</h3>
              <h4 className="text-sm mr-2">054)705-2838</h4>
              <h3 className="text-sm font-bold text-gray-300 mr-1">이메일</h3>
              <h4 className="text-sm mr-2">fcokj@daum.net</h4>
              <h3 className="text-sm font-bold text-gray-300 mr-1">센터카페</h3>
              <h4 className="text-sm mr-2">http://cafe.daum.net/fcokj</h4>
            </div>

            <span className="text-gray-400 mb-1 flex text-sm items-center">
              &copy; {new Date().getFullYear()} GYEONGJU SUPPORT CENTER FOR
              FOREIGN WOKERS. ALL RIGHTS RESERVED.
            </span>
          </div>
          <span className="flex text-gray-400 text-2xl py-2">
            <div className="bg-gray-400 rounded-full flex items-center justify-center w-6 h-6 mr-4">
              <span className="text-xs text-gray-700 flex justify-center items-center font-medium ">
                blog
              </span>
            </div>
            <FaFacebook className="mr-4" />
            <FaInstagram className="mr-4" />
            <FaTwitter className="mr-4" />
            <FaGoogle className="mr-4" />
          </span>
        </div>
      </div>
    </>
  );
}

export default TailPage;
