import React from "react";
import IntroSubtitle from "./IntroSubtitle";

function IntroPage3() {
  return (
    <>
      <IntroSubtitle />

      <div className="max-w-7xl mx-auto mt-24 flex flex-col justify-center items-center mb-20">
        <h2 className="font-medium text-4xl mb-20">시설현황</h2>
        <div className="mx-auto max-w-7xl grid grid-cols-3 mb-32">
          <div className="flex flex-col justify-center items-center text-xl">
            <img src="images/intros/intro1.jpg" alt="image1" width="400" />
            <h4>법인 외부 전경</h4>
          </div>
          <div className="flex flex-col justify-center items-center text-xl">
            <img src="images/intros/intro2.jpg" alt="image2" width="400" />
            <h4>사무실 / 상담실</h4>
          </div>
          <div className="flex flex-col justify-center items-center text-xl">
            <img src="images/intros/intro3.jpg" alt="image3" width="400" />
            <h4>제 1 강의실</h4>
          </div>
          <div className="flex flex-col justify-center items-center text-xl">
            <img src="images/intros/intro4.jpg" alt="image4" width="400" />
            <h4>제 2 강의실</h4>
          </div>
          <div className="flex flex-col justify-center items-center text-xl">
            <img src="images/intros/intro5.jpg" alt="image5" width="400" />
            <h4>휴게 공간</h4>
          </div>
          <div className="flex flex-col justify-center items-center text-xl">
            <img src="images/intros/intro6.jpg" alt="image6" width="400" />
            <h4>휴게 공간</h4>
          </div>
        </div>
        <h2 className="font-medium text-4xl mb-20">시설배치도</h2>
        <div className="mx-auto w-full flex justify-center mb-10">
          <img src="images/intros/intro7.jpg" alt="intro7" width="100%" />
        </div>
      </div>
    </>
  );
}

export default IntroPage3;
