import React from "react";
import { Link } from "react-router-dom";

function IntroSubtitle() {
  return (
    <>
      <div
        className="bg-white w-full h-72 flex flex-col justify-center items-center"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage:
            "url('https://images.unsplash.com/photo-1629206896310-68433de7ded1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=976&q=80')",
        }}
      >
        <h2 className="font-medium text-4xl text-white">법인소개</h2>
        <h4 className="font-light text-sm uppercase text-gray-200 mt-2">
          ABOUT THE CORPORATION
        </h4>
      </div>
      <div className="flex items-center justify-center py-6 border border-b-1">
        <Link to="/intro1">
          <span className="px-6 border-r border-gray-200">인사말</span>
        </Link>
        <Link to="/intro2">
          <span className="px-6 border-r border-gray-200">연 혁</span>
        </Link>
        <Link to="/intro3">
          <span className="px-6 border-r border-gray-200">시설현황</span>
        </Link>
        <Link to="/intro4">
          <span className="px-6">찾아오시는 길</span>
        </Link>
      </div>
    </>
  );
}

export default IntroSubtitle;
